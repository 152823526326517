<template>
  <div>
    <NewMessageTip v-if="hasUnansweredDialogs && !userInfo.disableHint && $route.name === 'newDialog'" />

    <el-form
      ref="newForm"
      :model="editMessage"
      :rules="rules"
      class="e-dialog__new"
    >
      <div
        v-if="loading || loadingDone"
        class="e-dialog__loading"
      >
        <loading v-if="loading" />
      </div>

      <el-input
        v-model="editMessage.subject"
        :placeholder="`${$t('system.subject')}...`"
        class="e-dialog__new-subject"
      />

      <div class="e-dialog__answer-field">
        <TiptapEditor
          :key="editMessage ? editMessage.id : 1"
          ref="messageEditor"
          v-model="editMessage.body"
          class="message-editor-tiptap"
          :placeholder="$t('system.writeAMessage')"
          :actions="editorOptions"
          @change="debouncedAutosave"
        />

        <div
          :class="{
            'e-dialog__charcount--alert': maxCharsExceeded
          }"
          class="e-dialog__charcount"
        >
          {{ charCountString }}
        </div>

        <transition name="fade-up">
          <div
            v-if="autoSaved && !autoSaving"
            class="e-dialog__autosave"
          >
            <IconCheck />
            <span>{{ $t('system.draft.autosaved') }}</span>
          </div>
        </transition>

        <transition name="fade-up">
          <div
            v-if="autoSaving"
            class="e-dialog__autosave"
          >
            <i class="el-icon-loading" />
            <span>{{ $t('system.draft.autosaving') }}</span>
          </div>
        </transition>
      </div>

      <footer class="e-dialog__footer">
        <el-form-item
          v-if="!currentDialog || (currentDialog && currentDialog.messages.filter((el) => el.outgoing === false).length === 0)"
          class="e-dialog__preferred-gender"
          :label="$t('system.message.preferredGenderLabel')"
        >
          <el-select
            v-model="editMessage.preferredGender"
            :placeholder="$t('system.message.preferredGenderPlaceholder')"
            filterable
            clearable
          >
            <el-option
              v-for="item in genderOptions"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <!-- Cancel -->
        <el-button
          class="e-btn--delete e-btn--ghost"
          @click="closeForm"
        >
          <IconClose />
          <span class="e-mobile-hidden">
            {{ $t('system.cancel') }}
          </span>
        </el-button>

        <!-- Delete -->
        <el-button
          v-if="editMessage.id"
          class="e-btn--delete e-btn--ghost"
          @click="deleteItem"
        >
          <IconTrash />
          <span class="e-mobile-hidden">
            {{ $t('system.delete') }}
          </span>
        </el-button>
        <!-- /Delete -->
        <el-tooltip>
          <div
            slot="content"
            v-html="submitTooltip"
          />

          <div class="e-btn--send">
            <el-button
              :disabled="
                autoSaving ||
                  editMessage.subject.length === 0 ||
                  editMessage.body.length === 0
              "
              type="tertiary"
              @click.prevent="submitForm('newForm')"
            >
              {{ $t('system.sendMessage') }}
              <IconSend />
            </el-button>
          </div>
        </el-tooltip>
      </footer>
    </el-form>

    <AbsencePrompt
      v-if="currentDialog"
      :confirm-is-open="absenceDialogOpen"
      :absent-until="currentDialog.absentDate"
      :forward-loading="absenceForwardLoading"
      :wait-loading="absenceWaitLoading"
      @close-dialog="absenceDialogOpen = false"
      @close-absent-dialog="closeAbsentDialog"
    />

    <DeletedPrompt
      v-if="currentDialog"
      :show="deletedPromptOpen"
      @close-dialog="closeDeletedPrompt"
      @forward-dialog="closeDeletedPrompt"
    />
  </div>
</template>

<script>
import api from 'api'
import debounce from 'lodash/debounce'
// import { autoSaveActions } from 'mixins'
import { mapGetters, mapActions } from 'vuex'

import TiptapEditor from '../../../shared/TiptapEditor'
import Loading from 'atoms/Loading/Loading'
import AbsencePrompt from 'molecules/AbsencePrompt/AbsencePrompt'
import DeletedPrompt from 'molecules/DeletedPrompt/DeletedPrompt'
import NewMessageTip from 'atoms/NewMessageTip/NewMessageTip'

// Icons
import IconTrash from 'assets/icons/trashcan.svg'
import IconSend from 'assets/icons/send.svg'
import IconClose from 'assets/icons/close.svg'
import IconCheck from 'assets/icons/arrows-circle-check.svg'

export default {
  name: 'MessageEditor',

  components: {
    AbsencePrompt,
    DeletedPrompt,
    IconCheck,
    IconClose,
    IconSend,
    IconTrash,
    Loading,
    NewMessageTip,
    TiptapEditor
  },

  // mixins: [autoSaveActions],

  props: {
    editMessage: {
      type: Object,
      default: () => { }
    }
  },

  data () {
    return {
      autoSaving: false,
      autoSaved: false,
      sending: false,
      deleteLoading: false,
      loading: false,
      loadingDone: false,
      maxChars: 10000,
      lastRoute: '/',
      absenceDialogOpen: false,
      deletedPromptOpen: false,
      absenceForwardLoading: false,
      absenceWaitLoading: false,
      rules: {
        subject: [
          { required: true, message: this.$t('system.message.subjectEmpty'), trigger: 'blur' }
        ],
        body: [{ required: true, message: this.$t('system.message.empty'), trigger: 'blur' }]
      },
      editorOptions: [
        {
          name: 'bold',
          icon: '<b>F</b>',
          title: this.$t('system.editor.bold')
        },
        {
          name: 'italic',
          icon: '<i>K</i>',
          title: this.$t('system.editor.italic')
        },
        {
          name: 'underline',
          icon: '<u>U</u>',
          title: this.$t('system.editor.underline')
        }
      ]
    }
  },

  computed: {
    ...mapGetters(['currentDialog', 'userInfo', 'hasUnsavedChanges', 'dialogs', 'settings']),

    charCountString () {
      return `${this.charCount} / ${this.maxChars}`
    },
    charCount () {
      const text = this.editMessage.body
      const div = document.createElement('div')
      div.innerHTML = text
      return div.innerText.length
    },

    maxCharsExceeded () {
      return this.charCount > this.maxChars
    },

    submitTooltip () {
      if (this.editMessage.subject === '' && this.editMessage.body === '') {
        return `${this.$t('system.message.subjectEmpty')}<br>${this.$t('system.message.empty')}`
      } else if (this.editMessage.subject === '' && this.editMessage.body !== '') {
        return `${this.$t('system.message.subjectEmpty')}`
      } else if (this.editMessage.subject !== '' && this.editMessage.body === '') {
        return `${this.$t('system.message.empty')}`
      } else {
        return `${this.$t('system.sendMessage')}`
      }
    },

    hasUnansweredDialogs () {
      return this.dialogs.filter((el) => el.outgoing === false).length > 0
    },

    debouncedAutosave () {
      return debounce(this.autosave,
        3000,
        {
          leading: false,
          trailing: true
        })
    },
    genderOptions () {
      const genderMap = window.genderEnum
      delete genderMap['GENDER_NONE']
      return Object.keys(genderMap).map((key) => ({
        name: this.$t(`system.gender.${key}`),
        value: genderMap[key]
      }))
    }
  },

  mounted () {
    if (this.currentDialog && this.currentDialog.absentDate !== null) {
      this.absenceDialogOpen = true
    }
    if (this.currentDialog && this.currentDialog.counselorDeleted) {
      this.deletedPromptOpen = true
    }
  },

  methods: {
    ...mapActions([
      'getDialogs',
      'getSentMessages',
      'resetEditMessages',
      'setConfirmOpen',
      'setEditMessage',
      'setMessageEditorOpen'
    ]),

    closeForm () {
      if (this.hasUnsavedChanges) {
        this.setConfirmOpen(true)
        return
      }
      this.setMessageEditorOpen(false)
    },

    prepareMessage (draft = true) {
      const data = Object.assign({}, this.editMessage)
      data.draft = draft
      data.subject =
        this.editMessage && this.editMessage.subject !== '' ? this.editMessage.subject : this.currentDialog ? this.currentDialog.subject : ''
      data.rootId = this.$route.params.dialogId
      data.parentId = null

      if (this.currentDialog && this.currentDialog.messages.length > 0) {
        const counselorMessages = this.currentDialog.messages.filter((el) => el.outgoing === false)
        data.parentId = counselorMessages.length > 0 ? counselorMessages[0].id : null
      }

      data.body = this.editMessage.body

      return data
    },

    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sendMessage()
        } else {
          return false
        }
      })
    },

    async sendMessage () {
      this.debouncedAutosave.cancel()
      this.loading = true
      this.sending = true

      this.editMessage.draft = false
      const formData = this.prepareMessage(false)
      formData.tempSave = false

      const id = this.editMessage.id || false
      const rootId = this.$route.params.dialogId
      const hasParent = !!this.editMessage.parentId

      try {
        const response = await api.call('sendMessage', { id: id, rootId: rootId }, formData)

        this.resetEditMessages()
        this.loading = false
        this.loadingDone = true
        this.settings.firstResponse && !hasParent
          ? this.$message({
            message: this.settings.firstResponse,
            type: 'success',
            duration: 0,
            dangerouslyUseHTMLString: true,
            showClose: true
          })
          : this.$message({
            message: this.$t('messages.dialog.sendSuccess'),
            type: 'success'
          })

        this.loadingDone = false

        await this.getDialogs()
        await this.getSentMessages()

        hasParent ? this.$router.push({
          name: 'dialogs'
        })
          : this.$router.push({
            name: 'dialog',
            params: { dialogId: response.data }
          })
      } catch (error) {
        this.$error(error)
        if (error.messages) {
          this.$message.error(this.$t(error.messages[0]))
        }
      } finally {
        this.sending = false
        this.loading = false
        this.absenceForwardLoading = false
        this.absenceWaitLoading = false
      }
    },

    async autosave () {
      if (this.sending || this.autoSaving) {
        return
      }
      this.autoSaving = true
      let message = this.prepareMessage(true)
      const reload = !message.id
      const response = await api.call('sendMessage', message, message)
      message = this.prepareMessage(true)
      message.id = response.data
      // delete message.body
      this.resetEditMessages(message)
      if (reload) {
        this.getDialogs()
      }
      this.autoSaving = false
    },

    deleteItem () {
      let message = this.$t('messages.dialog.deleteItem')
      if (this.editMessage.draft) {
        message = this.$t('messages.dialog.deleteDraft')
      }
      this.$confirm(message, this.$t('system.warning'), {
        confirmButtonText: this.$t('system.confirm'),
        cancelButtonText: this.$t('system.cancel'),
        type: 'warning'
      }).then(async () => {
        await api.call('deleteMessage', {
          id: this.editMessage.id,
          rootId: this.$route.params.dialogId
        })
        this.resetEditMessages()
        this.$message({
          type: 'success',
          message: this.$t('messages.dialog.deleteSuccess')
        })
        this.getDialogs()
        this.$router.push({
          name: 'dialogs'
        })
      })
    },

    closeAbsentDialog (forward) {
      this.absenceForwardLoading = forward === true
      this.absenceWaitLoading = forward !== true
      this.editMessage.forwardToCounselor = forward
      this.sending = false
      this.loading = false
      this.absenceDialogOpen = false
      // this.sendMessage()
    },
    closeDeletedPrompt () {
      this.deletedPromptOpen = false
      this.editMessage.forwardToCounselor = true
    }
  }
}
</script>

<style lang="scss">
@import '_assets/messageeditor';
.tiptap-editor.message-editor-tiptap .tiptap-editor__content--editor .ProseMirror {
  @include break(l) {
    height: 300px;
  }
}
</style>
